@import '../../../common/config.scss';

.hotspot {
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 25vw;

  &--ev9 {
    width: 35vw;
  }

  .debug & {
    background: rgba(255,0,0,.2);
  }

  // cursor: pointer;
  // display: inline-flex;
  text-decoration: none;

  &__inner {
    align-items: center;
    color: var(--color--text-light);
    display: flex;
    flex-flow: column;
    height: 0;
    justify-content: flex-start;
    padding-top: 65%;
    perspective: 1000px;
    position: relative;
    user-select: none;
    width: 100%;
  }

  &__icon {
    // cursor: pointer;
    position: relative;
    height: 50px;
    width: 50px;
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    top: 120%;

    span {
      animation: pulse calc(var(--transition-time) * 10) infinite linear;
      background: var(--color--text-light);
      border: 2px solid var(--color--text-light);
      border-radius: 100%;
      color: var(--color--text-dark);
      content: '';
      display: block;
      height: 100%;
      opacity: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;

      &:nth-child(2) {
        animation-delay: calc(var(--transition-time) * 3);
      }
      &:nth-child(3) {
        animation-delay: calc(var(--transition-time) * 6);
      }
      &:nth-child(4) {
        animation: none !important;
        opacity: 1;
        transform: translate(-50%, -50%) scale(.6);

        svg {
          transform: translateY(-1px);
        }
      }
    }

    &:hover {
      & + .hotspot__logo {
        transform: translate3d(-50%, -10%, 0);
      }
    }
  }

  &__logo {
    // cursor: pointer;
    bottom: 100%;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    transition: transform var(--transition-time--slow);
    user-select: none;
    width: var(--car--logo-width);

    img {
      display: block;
      width: 100%;
    }
  }

  &__engines {
    animation: fadeIn 1000ms forwards;
    align-self: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    .engine {
      margin-left: calc(var(--gap--1) / 2);
      margin-right: calc(var(--gap--1) / 2);
      // width: 80px;
    }
  }

  &__carname {
    font: var(--fs--h4) / normal var(--ff--bold);
    text-align: center;
    margin: var(--gap--2) 0 var(--gap--1);
    text-shadow: var(--text-shadow);
    // text-transform: uppercase;
    z-index: 2;
    position: relative;
  }

  &__price,
  &__label,
  &__cardescription {
    background: radial-gradient(closest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0,0,0,0) 50%);
    font-family: var(--ff--medium);
    text-align: center;
    text-shadow: var(--text-shadow);
  }

  &__cardescription {
    margin-top: 0;
  }

  &__price {
    transform: translateY(-50%);
    white-space: nowrap;
    margin-top: var(--gap--4);

    span {
      backdrop-filter: blur(4px);
      display: inline-block;
      font-size: var(--fs--normal);
      padding: 0 10px;
      background: radial-gradient(closest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0,0,0,0) 50%);
      font-weight: bold;
      border-radius: 100%;
      overflow: hidden;
    }
  }

  &__label {
    transform: translateY(-20%);
  }

  &__badge {
    position: absolute;
    left: 50%;
    bottom: 150px;
    transform: translateX(-50%);
    width: 70px;
    transition: position var(--transition-time);

    &--electric {
      bottom: 180px;
    }
  }

  @media screen and (min-width: map-get($map: $sections, $key: 2)) {
    width: 16vw;

    &--ev9 {
      width: 30vw;
    }

    &__icon {
      top: 100%;
    }

    &__carname {
      font-size: var(--fs--h2);
    }

    &__badge {
      bottom: 180px;
      width: 80px;

      &--electric {
        bottom: 220px;
      }
    }
  }

  @media screen and (min-width: map-get($map: $sections, $key: 6)) {
    width: 12vw;

    &--ev9 {
      width: 18vw;
    }

    &__price, &__label {
      transform: translateY(-50%);
    }

    &__badge {
      bottom: calc(100% + 100px);
      width: 90px;

      &--electric {
        bottom: calc(100% + 140px);
      }
    }
  }
}
