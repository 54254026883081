@import '../../../common/config.scss';

.powertrain {
  background-color: var(--color--neutrals-0);
  // box-shadow: var(--box-shadow);
  display: flex;
  flex-flow: column;
  margin-bottom: var(--gap--2);
  padding: 0;

  &__wrapper{
    display: flex;
  }

  &--highlighted {
    border: 1px solid var(--color--neutrals-9);
  }

  &__header {
    align-items: center;
    display: flex;
    flex-flow: column;
    justify-content: space-between;

    &-highlight {
      background-color: var(--color--neutrals-9);
      color: var(--color--neutrals-0);
      font-size: var(--fs--xsmall);
      font-weight: bold;
      opacity: 0;
      padding: var(--gap--1);
      width: 100%;

      p {
        margin: 0;
        pointer-events: none;
        visibility: hidden;
        white-space: nowrap;
      }

      .powertrain--highlighted & {
        opacity: 1;

        p {
          pointer-events: all;
          visibility: visible;
        }
      }
    }

    & > div {
      align-items: center;
      border-radius: var(--box-border-radius);
      display: flex;
      justify-content: center;
      // margin-left: var(--gap--2);

      &:first-child {
        margin-left: 0;
      }
    }

    .powertrain__model {
      padding: var(--gap--1);
      flex-flow: column;
      align-items: center;
    }

    .powertrain__type {
      align-items: center;
      flex-flow: column;
      color: var(--color--neutrals-4);


      .engine {
        align-items: center;
        display: flex;
        flex-flow: column;
        height: 100%;
        justify-content: center;
        min-width: 80px;
        width: auto;
        background: transparent;
        color: currentColor;
        border-color: currentColor;
        padding: var(--gap--1);

        .icon {
          height: 50px;
          width: 50px;
        }

        &__label {
          font-size: var(--fs--xxsmall);
        }
      }
    }

    .powertrain__prices {
      margin-bottom: var(--gap--2);
      margin-left: 0;
      margin-top: var(--gap--2);
    }
  }

  &__model {
    // background: var(--color--neutrals-2);
    width: 100%;
    flex-grow: 1;

    .model__name {
      font-size: var(--fs--xlarge);
    }

    .model__tag {
      display: flex;
      font-size: var(--fs--xsmall);
      font-family: var(--ff--bold);
      border: 1px solid var(--color--neutrals-9);
      border-radius: 20px;

      &__power {
        padding: 2px 15px;
        text-align: center;
      }

      &__engine {
        position: relative;
        background-color: var(--color--neutrals-9);
        color: white;
        border-radius: 0 20px 20px 0;
        padding: 2px 10px;
        text-align: center;}
    }
  }

  &__prices {
    border-radius: var(--box-border-radius);
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    align-items: center;
    padding: var(--gap--1);

    @media screen and (min-width: map-get($map: $sections, $key: 9)) {
      width: 60%;
    }

    &__multiple {
      flex-direction: column;
      align-items: center;

      &__pricelines {
        justify-content: space-evenly;
        align-items: baseline;
        padding: var(--gap--1);
        margin-top: var(--gap--1);
        display: block;

        @media screen and (min-width: map-get($map: $sections, $key: 2)) {
          display: flex;
        }

        .pricebox  {
          & + .pricebox {
            margin: 10px 0 0 0;

            @media screen and (min-width: map-get($map: $sections, $key: 2)) {
              margin: 0 0 0 12px;
            }

          }
        }

        .powertrain__price {
          &+.powertrain__price {
            margin-top: 5px;

            @media screen and (min-width: map-get($map: $sections, $key: 2)) {
              padding-left: 20px;
            }
            @media screen and (min-width: map-get($map: $sections, $key: 3)) {
              padding-left: 0;
            }
            @media screen and (min-width: map-get($map: $sections, $key: 5)) {
              padding-left: 20px;
            }
            @media screen and (min-width: map-get($map: $sections, $key: 7)) {
              padding-left: 0;
            }
            @media screen and (min-width: map-get($map: $sections, $key: 8)) {
              padding-left: 20px;
            }
          }

          &__label {
            justify-content: center;
            white-space: nowrap;
          }
        }
      }
    }
  }

  &__price {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    font: var(--fs--small) / 1.5 var(--ff--medium);

    &__name{
      font: var(--fs--small) / normal var(--ff--bold);
      white-space: nowrap;
    }

    &__easymove {
      align-items: center;
      display: flex;
      font: var(--fs--small) / normal var(--ff--medium);

      &__amount {
        white-space: nowrap;
        font: var(--fs--small) / normal var(--ff--medium);
      }
    }

    &__info__easymove &__label__infobutton {
      display: inline;

      .priceinfo {
        vertical-align: unset;

        .button__label {
          font-size: var(--fs--small);
          padding: 0;
        }
      }
    }

    &__label {
      white-space: nowrap;
    }

    &__financial_renting,
    &__kia_lease {
      .powertrain__price {
        &__name {
          font-family: var(--ff--bold);
          font-size: var(--fs--xxsmall);
          color: var(--color--neutrals-4);
          white-space: nowrap;
        }

        &__label {
          display: flex;
          font-size: var(--fs--xsmall);

          &__infobutton{
            display: flex;
            width: 10%;
            align-items: center;
            justify-content: center;
            padding: 0 0 0 var(--gap--1);

            .icon{
              color: var(--color--secondary)
            }
          }
        }
      }
    }

    .priceinfo {
      & > .icon {
        font-size: 100%;
      }
    }

    .price__amount {
      font-size: var(--fs--xsmall);
      white-space: nowrap;
    }

    .price__extra {
      &:empty {
        display: none;
      }

      &--highlight {
        border: 1px solid currentColor;
        padding: 2px 10px;
        text-align: center;
        margin-top: 3px;
        margin-bottom: 3px;
        min-height: 1.5lh;
      }
    }

    &__easymove,
    &__kia_lease,
    &__financial_renting {
      text-align: center;
    }
  }

  &__example {
    margin-bottom: var(--gap--5);
  }

  &__actions {
    margin-top: auto;
  }

  &__exhaust {
    font-size: var(--fs--xsmall);
    text-align: center;
  }

  &__devider{
    width: 75%;
    border-top: 1px solid var(--color--neutrals-3);
  }

  &__content {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    align-items: center;
  }

  &__package {
    width: 75%;
    ul {
      padding-left: 1.5em;
    }
    // margin-bottom: auto;
    // &__title {
    //   text-align: left;
    //   width: 75%;
    // }
  }

  &__image {
    // margin-top: auto;
    position: relative;
    text-align: center;

    img {
      display: block;
      width: 100%;
    }

    .button {
      width: 100%;
    }

    &__highlight {
      left: var(--gap--6);
      position: absolute;
      top: var(--gap--6);
      width: 100px;
    }
  }

  .popup {
    left: -24px;
    transform: none;

    @media screen and (min-width: map-get($sections, 2)) {
      left: 20px;
      top: 20px;
      z-index: 200;
    }

    &:before {
      margin-left: var(--gap--5);
    }
  }

  .priceinfo__easymove {
    display: inline;
    color: var(--color--primary);
  }

  .price__name {
    white-space: nowrap;
  }

  @media screen and (min-width: map-get($map: $sections, $key: 2)) {
    &__price {
      position: relative;

      &:last-child {
        &:before {
          content: '';
        }
      }
    }
    &__package {

      margin-bottom: auto;
    }
  }
  @media screen and (min-width: map-get($map: $sections, $key: 8)) {
    &__header {
      flex-flow: column;

      .powertrain__prices {
        margin-top: 0;
        margin-left: var(--gap--1);
        height: 100%;
        flex-flow: column;
        text-align: right;
        width: auto;
      }

      .powertrain__type {
        margin-left: var(--gap--1);
      }
    }

    .powertrain__price {
      // width: 100%;

      &:first-child {
        margin-bottom: var(--gap--2);
      }
    }

    &__model {
      .model__name {
        font-size: var(--fs--xlarge);
      }

      .model__tag {
        display: flex;
        font-size: var(--fs--xsmall);
        font-family: var(--ff--bold);
        border: 1px solid var(--color--neutrals-9);
        border-radius: 20px;

        &__power {
          padding: 2px 15px;
          text-align: center;
        }

        &__engine {
          position: relative;
          background-color: var(--color--neutrals-9);
          color: white;
          border-radius: 0 20px 20px 0;
          padding: 2px 10px;
          text-align: center;
        }
      }
    }

    &__content {
      flex-flow: column;
      align-items: center;
    }

    &__package {
      width: 75%;
      margin-bottom: auto;
    }

    &__image {
      width: 60%;

      .button {
        width: 83.5%;
      }
    }
    .price__name{
      font-family: var(--ff--bold);
      font-size: var(--fs--xxsmall);
      color: var(--color--neutrals-4);
    }
  }

  &__kiaprivatelease {
    position: relative;
    max-width: 60%;
    padding: 15px 0 20px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 45%;
      width: 10%;
      height: 2px;
      background-color: var(--color--neutrals-9);
    }

    &__noprice {
      text-align: center;
      font-size: var(--fs--small);
    }

    &__price {
      text-align: center;
      font-size: var(--fs--small);

      .powertrain__price__label__infobutton {
        margin-left: 3px;
        display: inline;

        .icon {
          color: var(--color--secondary);
      }
      }
    }
  }
}

.car__powertrains:has(.price__extra--highlight) {
  .price__extra:empty {
    display: block;
    min-height: 1.5lh;
    margin: 3px 0;
  }
}
