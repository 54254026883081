.page--contact {
  .button--back {
    margin-left: 0;
    margin-right: auto;
    color: var(--color--text-light);
  }

  .navigation {
    .navigation__link--active {
      &:before {
        background: var(--color--neutrals-2);
      }
    }
  }
  
  &.dark {
    .button:not([type="submit"]):not(.navigation__link):not(.success__link) {
        color: var(--color--primary) !important;
    }
  }

  .page__inner {
    margin: 0 auto;
    max-width: var(--width--section-9);
  }

  &.dark {
    .logo {
        color: var(--color--text-dark);
    }
    .navigation {
      color: var(--color--text-dark);
    }
  }
}

.contact {
  &__wrapper {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: var(--gap--6);
    margin-top: var(--gap--6);
    max-width: var(--width--section-2);
  }

  &__radios {
    align-items: stretch;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  &__radio {
    align-items: center;
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    justify-content: center;
    width: calc(33.33%);

    &.button {
      flex-flow: row nowrap;
    }

    label {
      align-items: center;
      border-radius: var(--box-border-radius);
      cursor: pointer;
      display: flex;
      flex-flow: column;
      font: inherit;
      height: 100%;
      justify-content: space-between;
      padding: var(--gap--1) var(--gap--4) var(--gap--1);
      text-align: center;
      width: 100%;

      .icon {
        font-size: var(--fs--icon);
        margin: 0 auto;
      }

      span {
        margin-top: var(--gap--1);
      }

      &:before {
        content: initial !important;
      }
    }

    .form__input {
      height: 1px;
      left: -9999px;
      opacity: .01;
      position: absolute;
      top: -9999px;
      width: 1px;

      & + label {
        // background: var(--color--neutrals-2);
        border-top: 3px solid transparent;
        color: var(--color--text-dark);
        opacity: .6;

        &:hover {
          // background: var(--color--neutrals-3);
          border-color: var(--color--neutrals-4);
        }
      }

      &:checked + label {
        // background: var(--color--primary);
        border-color: var(--color--primary);
        opacity: 1;
        // color: var(--color--text-light);

        // &:hover {
        //   background: var(--color--primary-hover);
        // }
      }
    }
  }
}

.predictions {
  background: var(--color--neutrals-0);
  border: 1px solid var(--color--neutrals-4);
  border-radius: var(--input-border-radius);
  color: var(--color--text-dark);
  left: 0;
  max-height: 164px;
  overflow: auto;
  position: absolute;
  top: 100%;
  width: 100%;

  &:empty { display: none; }

  .prediction {
    border-bottom: 1px solid var(--color--neutrals-4);
    cursor: pointer;
    padding: var(--gap--3) var(--gap--4);

    &:last-child {
      border: none;
    }

    &:hover {
      background-color: var(--color--neutrals-2);
      // color: var(--color--text-light);
    }
  }
}

.dealers {
  background: var(--color--neutrals-0);
  border: 1px solid var(--color--neutrals-4);
  border-radius: var(--input-border-radius);
  max-height: 164px;
  overflow: auto;

  &:empty {
    display: none;
  }
}

.dealer {
  align-items: center;
  background: var(--color--neutrals-0);
  color: var(--color--text-dark);
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: var(--gap--2) var(--gap--3);
  transition: background-color var(--transition-time), color var(--transition-time);

  p { margin: 0; }

  &:hover {
    background: var(--color--neutrals-2);
  }

  &.dealer--active {
    background: var(--color--neutrals-2);
    // color: var(--color--text-light);
    cursor: default;
  }
}
