.language__list{
  display: none;
  margin: 0;
  padding-left: .95em;

  &__wrapper {
    position: relative;

    .button {
      align-items: flex-start;
    }
  }

  &.open {
    background: var(--color--neutrals-0);
    display: block;
    position: absolute;
    left: 0;
    top: 2em;
    list-style: none;

    a, a:hover {
      color: var(--color--neutrals-9);
    }
  }
}

.language__link--active{
  display: none;
}
