.form__item--select {
  flex-shrink: 0;
  position: relative;

  .icon--arrow-down {
    font-size: var(--fs--xsmall);
    height: auto;
    pointer-events: none;
    position: absolute;
    right: var(--gap--2);
    top: var(--gap--5);
    transform: translateY(-50%);
  }
}

.form__select {
  appearance: none;
  cursor: pointer;
  padding-right: calc(var(--gap--2) + 1em);
  width: 100%;
}
