.carousel {
  // border: 1px solid var(--color--neutrals-6);
  border-radius: var(--box-border-radius);
  // background: var(--color--neutrals-0);
  // overflow: hidden;

  .slick-track {
    display: flex !important;
  }
  .slick-slide {
    height: inherit !important;

    > div {
      height: 100%;
    }
  }
  .slick-list{
    overflow: visible;
  }

  &__wrapper {
    position: relative;
  }

  &__item {
    &__visual {
      height: 100%;
    }
    img {
      pointer-events: none;
      height: 100%;
      object-fit: cover;
    }
  }

  &__action.button {
    background: rgba(0,0,0,0.28);
    border-radius: 50px;
    padding: var(--gap--4);
    color: var(--color--text-light);
    font-size: var(--fs--icon);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 120;

    .icon{
      width: 0.6em;
      height: 0.6em;
    }
    &.carousel__action--left {
      left: 0;
    }
    &.carousel__action--right {
      right: 0;
    }
  }

  &__item__video {
    height: 100%;
    // padding-top: 56.26%;
    position: relative;
    width: 100%;

    iframe {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &__item__link {
    display: block;

    .icon {
      height: 60px;
      left: 50%;
      position: absolute;
      pointer-events: none;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 60px;
    }

  }

  &__item {
    align-items: center;
    display: flex !important;
    height: 100%;
    flex-flow: column;
    position: relative;
    justify-content: center;
  }
}

slick-dots.slick-thumb {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.slick-dots.slick-thumb li {
  width: 82px;
  height: 3px;
  margin: 10px 4px 0 0;
  transition: all 0.5s ease-in-out;
  will-change: width;
  background-color: var(--color--neutrals-3);

  button {
    height: 100%;
    width: 100%;
    font-size: 0;


    &:before {
      content: initial;
    }
  }
}

.slick-dots.slick-thumb li.slick-active {
  background-color: var(--color--primary);
}

.slick-dots{
  display: flex !important;
  justify-content: center;
}
