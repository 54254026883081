.engine {
  // background: var(--color--brand-5);
  background: var(--color--transparent);
  border: 1px solid transparent;
  border-color: var(--color--neutrals-0);
  border-radius: 15px;
  color: var(--color--text-light);
  padding: var(--gap--2);
  text-align: center;
  // width: 75px;

  // .icon {
  //   color: transparent;
  //   display: flex;
  //   height: 30px;
  //   margin-left: auto;
  //   margin-right: auto;
  //   width: 30px;
  // }

  &__label {
    text-transform: uppercase;
    white-space: nowrap;
    margin-bottom: -2px;
  }

  &--ice_sessence,
  &--ice_diesel {
    // background-color: var(--color--petrol);

    .icon {
      color: transparent;
    }
  }
}
