@import '../../../common/config.scss';

.page--showroom {
  margin-top: 0 !important;
  position: relative;

  &:after {
    animation: fadeOut var(--transition-time--slow) forwards;
    bottom: 0;
    content: '';
    left: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
  }

  &.loading {
    &:after {
      animation: fadeIn var(--transition-time--slow) forwards;
      background: rgba(0,0,0,0.4);
      pointer-events: all;
    }
  }

  .page__inner {
    height: 100vh;
    overflow: hidden;
    position: relative;
    width: 100vw;
  }
}

.showroom {
  &__hotspots {
    display: none;
  }

  &__hotspot {
    z-index: 10;
  }

  &__actions {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    left: 0;
    padding: var(--gap--2);
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100vw;
    z-index: 500;

    .button {
      pointer-events: all;
    }

    .icon {
      color: var(--color--text-light);
      font-size: var(--fs--h1);

      filter: drop-shadow( 0 0 2px rgba(0, 0, 0, .4));
    }
  }

  &__pano {
    height: 100%;
    width: 100%;
  }

  &__car {
    animation: fadeIn var(--transition-time--slow) forwards;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    user-select: none;
    width: 100%;
    z-index: 100;

    &.hidden {
      animation: fadeOut var(--transition-time--slow) forwards;
    }
  }

  &__switch {
    display: flex;
    flex-flow: column;
    justify-content: center;
    bottom: 30px;
    // left: 50%;
    // transform: translateX(-50%);
    position: fixed;
    z-index: 1;
    width: 100%;

    .promo {
      // border-bottom: 1px solid var(--color--text-light);
      // margin-bottom: 1px;

      &__wrapper {
        border: 3px solid var(--color--text-light);
      }

      & + .eswitch {
        background: var(--color--text-light);
        color: var(--color--text-dark);

        .eswitch__toggle {
          background: currentColor;
        }
      }
    }

    .eswitch {
      border-bottom: 1px solid var(--color--text-light);
      display: flex;
      justify-content: center;
      margin-bottom: 1px;
      width: 100%;
    }
  }

  &__campaigns, &__hotspots {
    display: none;
  }

  &--electric {
    .showroom__hotspot--electric {
      .hotspot__icon {
        span {
          background: var(--color--electric-dark);
          border-color: var(--color--electric);
        }
      }
    }
  }

}
