@import '../../../common/config.scss';

.page--maintenance {
  // background: var(--color--neutrals-8) no-repeat center center / cover;
  // background-image: linear-gradient(to bottom, rgba(0,0,0,.3), rgba(0,0,0,0.3)), url(/graphics/bg-mobile.jpg);
  background: url(/graphics/bg-mobile.jpg) no-repeat center center / cover;

  .header {
    padding-top: var(--gap--5);

    &__content {
      justify-content: center;
    }

    .logo {
      color: var(--color--text-light);
    }
  }

  .info__content {
    p {
      font-size: var(--fs--small);
      margin: 0;

      & > strong:first-child:last-child {
        display: inline-block;
        font-size: var(--fs--h4);
        line-height: 1.45;
        margin-bottom: var(--gap--2);
      }
    }
  }

  @media screen and (min-width: map-get($sections, 4)) {
    // background-image: linear-gradient(to bottom, rgba(0,0,0,.3), rgba(0,0,0,0.3)), url(/graphics/bg-desktop.jpg);
    background-image: url(/graphics/bg-desktop.jpg);
  }
}
