.switch {
    --ball-radius: var(--gap--4);

    align-items: center;
    border-radius: var(--button-border-radius);
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    position: relative;

    &:after {
      border-radius: inherit;
      content: '';
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: opacity var(--transition-time) ease-in-out;
      width: 100%;
      z-index: -1;
    }

    &__toggle {
      background: var(--color--neutrals-6);
      border: 1px solid var(--color--neutrals-1);
      border-radius: var(--ball-radius);
      cursor: pointer;
      flex-shrink: 0;
      height: calc(var(--ball-radius) + 2px);
      position: relative;
      transition: background-color var(--transition-time);
      width: calc(var(--ball-radius) * 2.5);

      span {
        background: var(--color--neutrals-0);
        border: 1px solid var(--color--neutrals-3);
        border-radius: 100%;
        height: var(--ball-radius);
        left: -2px;
        position: absolute;
        top: 50%;
        transform: translate3d(2px, -50%, 0);
        transition: transform var(--transition-time), background-color var(--transition-time);
        width: var(--ball-radius);
      }
    }

    &__leftLabel,
    &__rightLabel {
      // color: var(--color--neutrals-1);
      font-size: var(--fs--xsmall);
    }

    &__leftLabel + &__toggle,
    &__toggle + &__rightLabel {
      margin-left: calc((var(--ball-radius) / 2) + var(--gap--2));
    }

    &--active {
      .switch__toggle {
        span {
          transform: translate3d(-50%, -50%, 0) translateX(calc((var(--ball-radius) * 2) - 2px));
          left: 0;
        }
      }

      &:after {
        opacity: 1;
      }
    }
  }
