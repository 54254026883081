.carconfigurator {
  background: var(--configurator--bg) no-repeat center center/cover;
  position: relative;
  margin: 0 auto;
  height: 100vh;
  // max-width: 1920px;
  z-index: 1;


  &:before {
    background: rgba(0,0,0,0.67);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }

  &__preload {
    color: var(--color--text-light);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: none;
  }

  .nd-experience-visualiser {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  #gf-visualiser:not(.has-touch) .my_canvas_3d canvas {
    cursor: url('../../01_atoms/icons/graphics/360-cursor.svg'), move;

  }

  .wrapper, #test-container, #gf-ui-controls, #gf-visualiser-poster, .pre-loader, .experience, .gf-poster-primary {
    height: 100% !important;
  }

  .gf-loading-modal,
  .placeholder,
  .seeker,
  .fullscreen,
  .switch-tour,
  .external-link,
  .navigation,
  .gf-header-bar,
  .gf-tertiary-control,
  .gf-secondary-control,
  .gf-primary-control,
  #hotspot_container_detail,
  #gf-iframe,
  .gf-loading-modal,
  #gf-share-url-box,
  #gf-tertiary-control,
  #gf-secondary-control,
  #gf-primary-control,
  #hotspot_container_detail,
  #gf-share-url-box {
    display: none !important;
  }

  .experience .pre-loader {
    background: var(--configurator--bg) no-repeat center center/cover;
    color: var(--color--text-light);
    font: normal var(--fs--h4) / normal var(--ff--bold);
    text-transform: none;
    position: relative;

    &:before {
      background: rgba(0,0,0,0.67);
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }
  }
}
