.icon--close {
  &:before, &:after {
    background: currentColor;
    border-radius: 2px;
    content: '';
    height: .125em;
    display: block;
    left: 50%;
    position: absolute;
    transform-origin: center center;
    top: 50%;
    width: 100%;
  }

  &:before {
    transform: translate3d(-50%, -50%, 0) rotate(45deg);
  }

  &:after {
    transform: translate3d(-50%, -50%, 0) rotate(-45deg);
  }
}

