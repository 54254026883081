@import '../../../common/config.scss';

.page--language {
  background: var(--color--neutrals-8) no-repeat center center / cover;
  background-image: linear-gradient(to bottom, rgba(0,0,0,.3), rgba(0,0,0,0.3)), url(/graphics/bg-mobile.jpg);
  position: relative;

  .header {
    padding-top: var(--gap--5);

    &__content {
      justify-content: center;
    }

    .logo {
      color: var(--color--text-light);
    }
  }

  .page__inner {
      align-items: center;
      min-height: 100vh;
      text-align: center;
      justify-content: center;
  }

  @media screen and (min-width: map-get($sections, 4)) {
    background-image: linear-gradient(to bottom, rgba(0,0,0,.3), rgba(0,0,0,0.3)), url(/graphics/bg-desktop.jpg);

    .header {
      &__content {
        justify-content: space-between;
      }
    }
  }
}
