.logo {
  color: var(--color--neutrals-0);
  display: block;
  transition: color var(--transition-time);

  &__link {
    color: inherit;
    display: block;
    text-decoration: none;
    outline: none !important;
  }
}
