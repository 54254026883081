@import '../../../common/config.scss';

.box {
  background: var(--color--neutrals-0);
  border-radius: var(--box-border-radius);
  color: var(--color--text-darker);

  &__content {
    padding: var(--gap--2);
  }

  @media screen and (min-width: map-get($sections, 1)) {
    &__content {
      padding: var(--gap--5);
    }
  }

}
