.pricebox {
    width: 185px;

    fieldset {
        border: 1px solid #ffffff;
        border-radius: 2px;
        padding: 7px 15px 11px 15px;
        width: 100%;
        box-sizing: border-box;
        text-align: center;

        legend {
            margin: 0 auto;
            padding: 0 18px;
            text-transform: uppercase;
            text-align: center;
            font-size: var(--fs--normal);
            font-family: var(--ff--bold);
            color: var(--color--text-light);
            line-height: var(--fs--large);
        }
    }

    &__content {
        &__title {
            margin: 0;
        }

        &__price {
            margin: 0;
            font-size: 37px;
            line-height: 35px;
            font-family: var(--ff--bold);
            margin-bottom: 2px;
        }

        &__vatinfo {
            margin: 0;

            .powertrain__price__label__infobutton,
            .carpreview__price__infobutton {
                display: inline-block;
                padding-left: 4px;
            }

            .powertrain__price__label__infobutton .priceinfo,
            .carpreview__price__infobutton {
                .icon {
                    margin-left: 0;
                }
            }
        }

        &__tco {
            margin: 0;
            font-family: var(--ff--bold);
        }
    }
    
    &.mod-black {
        fieldset {
            border-color: #000000;

            legend {
                color: #000000;
            }
        }

        .pricebox {
            &__content {
                &__vatinfo {
                    .powertrain__price__label__infobutton .priceinfo,
                    .carpreview__price__infobutton {
                        .icon {
                            color: #000000;
                        }
                    }
                }
            }
        }
    }
    
    &.mod-small {
        width: 136px;

        fieldset {
            padding: 5px 10px 5px 10px;

            legend {
                padding: 0 16px;
                font-size: var(--fs--xxsmall);
                line-height: var(--fs--xsmall);
            }
        }

        .pricebox {
            &__content {
                &__title {
                    font-size: var(--fs--xxsmall);
                }

                &__price {
                    font-size: 26px;
                    line-height: 24px;
                }
                
                &__vatinfo {
                    font-size: var(--fs--xxsmall);
                }

                &__tco {
                    font-size: var(--fs--xsmall);
                }
            }
        }
    }
}
