@import "../../../common/config.scss";

.form__row {
  align-items: flex-start;
  display: flex;
  flex-flow: row wrap;
  justify-content: stretch;

  .form__item {
    margin-bottom: var(--gap--5);
    width: 100%;
  }

  @media screen and (min-width: map-get($sections, 3)) {
    flex-flow: row nowrap;
    justify-content: space-between;

    .form__item {
      margin-left: var(--gap--2);
      width: auto;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.form__item {
  align-items: flex-start;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.form__item--label-after {
  position: relative;

  label {
    display: block;
    position: absolute;
    top: var(--gap--2);
    left: var(--gap--4);
    // transform: translateY(-50%);
    transform-origin: left center;
    transition: transform var(--transition-time--fast);
    pointer-events: none;
    width: 100%;
  }

  .form__input {
    &::placeholder {
      opacity: 0;
    }

    &:not(:placeholder-shown) {
      padding: var(--gap--3) var(--gap--4) var(--gap--1);

      & + label {
        transform: translateY(-50%) scale(0.6);
      }
    }
  }
}

.form__input,
.form__select {
  background: var(--color--neutrals-0);
  border: 1px solid var(--color--primary);
  border-radius: var(--input-border-radius);
  font: inherit;
  padding: var(--gap--2) var(--gap--4);
  transition: border-color var(--transition-time);

  &:hover,
  &:focus {
    border-color: var(--color--primary);
    outline: none;
  }

  &:placeholder-shown {
    & + label {
      color: var(--color--primary);
    }
  }
}

.form__input {
  &:required {
    &:invalid:not(:placeholder-shown) {
      border-color: var(--color--negative);
    }
  }
}

.form__select {
  &:required {
    &:invalid {
      &.placeholder-shown {
        border-color: var(--color--negative);
      }
    }
  }
}

.form__item--error {
  .form__input {
    border-color: var(--color--negative);
  }
}
