@import '../../../common/config.scss';

.section, [class|="section"] {
    display: flex;
    flex-flow: column;
    margin-left: auto;
    margin-right: auto;
    // max-width: var(--width--max);
    padding-left: var(--gap--5);
    padding-right: var(--gap--5);
    width: 100%;
}
@each $name, $value in $sections {
  .section--#{$name} {
    max-width: calc(#{$value} + (2 * var(--gap--5)));
  }
}

@for $i from 0 to $gaps {
  .mt--#{$i} { margin-top: var(--gap--#{$i}); }
  .mr--#{$i} { margin-right: var(--gap--#{$i}); }
  .mb--#{$i} { margin-bottom: var(--gap--#{$i}); }
  .ml--#{$i} { margin-left: var(--gap--#{$i}); }

  .mx--#{$i} { margin-left: var(--gap--#{$i}); margin-right: var(--gap--#{$i}); }
  .my--#{$i} { margin-top: var(--gap--#{$i}); margin-bottom: var(--gap--#{$i}); }

  .pt--#{$i} { padding-top: var(--gap--#{$i}); }
  .pr--#{$i} { padding-right: var(--gap--#{$i}); }
  .pb--#{$i} { padding-bottom: var(--gap--#{$i}); }
  .pl--#{$i} { padding-left: var(--gap--#{$i}); }

  .px--#{$i} { padding-left: var(--gap--#{$i}); padding-right: var(--gap--#{$i}); }
  .py--#{$i} { padding-top: var(--gap--#{$i}); padding-bottom: var(--gap--#{$i}); }

  .-mt--#{$i} { margin-top: calc(-1 * var(--gap--#{$i})); }
  .-mr--#{$i} { margin-right: calc(-1 * var(--gap--#{$i})); }
  .-mb--#{$i} { margin-bottom: calc(-1 * var(--gap--#{$i})); }
  .-ml--#{$i} { margin-left: calc(-1 * var(--gap--#{$i})); }
}
.mx--auto { margin-left: auto; margin-right: auto; }
.m--auto { margin: auto; }
.mt--auto { margin-top: auto; }
.mb--auto { margin-bottom: auto; }

.fullh { min-height: 100%; }
.fullw { width: 100%; }

.fullhs { min-height: 100vh; }
.fullws { width: 100vw; }
.fullscreen { height: 100vh; width: 100vw; }

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
}

picture {
  user-select: none;
  img {
    display: block;
    width: 100%;
  }
}

hr {
  border: none;
  display: block;
  height: 1px;
  opacity: .3;
}

table {
  table-layout: fixed;
  width: 100%;
}


html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

body {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}
