.info {
  animation: fadeIn var(--transition-time) forwards;
  left: 50%;
  max-height: 80vh;
  padding: 0 var(--gap--5);
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  visibility: visible;
  width: 100%;
  z-index: 990;

  &--hidden {
    animation: fadeOut var(--transition-time) forwards;
  }

  &:before {
    background: rgba(0, 0, 0, 0.67);
    content: '';
    display: block;
    height: 100vh;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
  }

  &__content {
    background: var(--color--neutrals-0);
    border-radius: var(--button-border-radius);
    color: var(--color--text--dark);
    padding: var(--gap--7) var(--gap--6);
    position: relative;
    overflow: auto;

    @media screen and (min-height: 730px) {
      padding: var(--gap--10) var(--gap--9);
    }
  }

  &__close {
    color: var(--color--primary);
    right: var(--gap--4);;
    position: absolute;
    top: var(--gap--4);
  }

  &--no-bg {
    &:before {
      content: initial;
    }
  }
}
