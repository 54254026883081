@import '../../../common/config.scss';

.popup {
  // animation: fadeOut var(--transition-time) forwards;
  color: var(--color--text-darker);
  font-size: var(--fs--xsmall);
  font-family: var(--ff--medium);
  left: 50%;
  margin-top: var(--gap--5);
  opacity: 0;
  position: absolute;
  pointer-events: none;
  text-shadow: none;
  text-align: left;
  // transition: opacity var(--transition-time);
  // top: 100%;
  transform: translate(-50%, 0);
  width: 93vw;
  z-index: 100;

  ul, li {
    margin-left: 1em;
    padding: 0em;
  }

  &:before {
    border: 10px solid transparent;
    border-bottom-color: var(--color--neutrals-0);
    // content: '';
    display: block;
    height: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: -10px;
    pointer-events: none;
    position: relative;
    width: 20px;
  }

  &__content {
    background: var(--color--neutrals-0);
    border-radius: var(--box-border-radius);
    box-shadow: var(--box-shadow);
    padding: var(--gap--4) var(--gap--5);
  }

  &--open {
    animation: fadeIn var(--transition-time) forwards;
    pointer-events: initial;
  }

  @media screen and (min-width: map-get($sections, 2)) {
    left: 50%;
    position: fixed;
  }
  @media screen and (min-width: map-get($sections, 3)) {
    margin-top: 0;
    position: absolute;
    top: 100%;
    transform: translate(-50%, 0) translateX(var(--gap--20));
    width: 350px;

    &:before {
      content: '';
      transform: translateX(calc(var(--gap--20) * -1));
    }
  }

  @media screen and (min-width: map-get($sections, 7)) {
    transform: translate(-50%, 0);

    &:before {
      transform: none;
    }
  }
}
