@import '../../../common/config.scss';

.footer {
  font-size: var(--fs--xxsmall);
  padding: var(--gap--2) 0;
  width: 100%;
  background: var(--color--primary);
  border-top: 1px solid var(--color--neutrals-3);
  z-index: 10;

  &__navigation {}
  &__copy {
    margin-top: var(--gap--2);
    text-align: center;
  }

  .navigation {
    justify-content: center;

    &__item {
      border-right: 1px solid var(--color--text-dark);
      text-align: center;

      &:last-child {
        border-right: none;
      }
    }
    &__link {
      color: var(--color--neutrals-0);
      font-size: var(--fs--xxsmall);

      &:before {
        content: initial;
      }

      &:hover {
        color: var(--color--neutrals-1);
      }
    }
  }

  &--fixed {
    bottom: 0;
    left: 0;
    position: absolute;
  }

  &--light {
    color: var(--color--text-light);
    text-shadow: var(--text-shadow);

    .navigation__item {
      border-color: var(--color--text-light);
    }
    .navigation__link {
      color: var(--color--text-light);
    }
  }

  @media screen and (min-width: map-get($sections, 4)) {
    font-size: var(--fs--xsmall);
    padding: var(--gap--2) 0;
    border-top: 1px solid var(--color--neutrals-3);
    .navigation {
      &__link {
        font-size: var(--fs--xsmall);
      }
    }
  }

  @media screen and (min-width: map-get($sections, 5)) {
    &__content {
      align-items: flex-end;
      flex-flow: row nowrap;
      justify-content: center;
    }

    &__copy {
      margin-left: auto;
      margin-top: 0;
    }

    .navigation {
      justify-content: space-between;
    }
  }
}
