.loading {
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;

  .loading__animation {
    margin: 0 auto;
    height: 60px;
    width: 60px;
  }

  &__inner {
    align-self: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
}

.page--loading {
  background: rgba(0,0,0,.67);
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 100;
}
