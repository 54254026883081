@import '../../../common/config.scss';

.priceinfo {
  display: inline-flex;
  font-size: inherit;
  text-transform: none;
  vertical-align: middle;

  & > .icon {
    cursor: pointer;
    // font-size: 60%;
    color: var(--color--brand-0);
  }

  &__subtitle {
    font-size: var(--fs--small);

    @media screen and (min-width: map-get($map: $sections, $key: 8)) {
      font-size: var(--fs--xxsmall);
    }
  }

  &__price{
    color: var(--color--primary);
  }

  &__easymove,
  &__kia_lease,
  &__financial_renting {
    font-size: var(--fs--xsmall);
    white-space: initial;
  }

  &__table{
    td {
      line-height: 1.3;
      padding: 1em 0;
    }
  }
  &__promo {
    margin: var(--gap--1) 0;

    p {
      display: block !important;
      margin: 0 0 var(--gap--1);

      &:last-child {
        margin-bottom: 0;
      }

      &:empty {
        display: none;
      }
    }
  }

  .button {
    color: inherit;
  }

  table {
    td:last-child {
      text-align: right;
      width: 80px;
    }
  }

  &__actions {
    display: flex;
    flex-flow: column;
  }

  @media screen and (min-width: map-get($map: $sections, $key: 2)) {
    position: relative;
  }
}
