@import '../../../common/config.scss';

#launcher {
  display: none !important;
}

.chat {
  bottom: calc(env(safe-area-inset-bottom, 20px) + var(--gap--5));
  position: fixed;
  right: var(--gap--5);
  z-index: 99;

  .button {
    border-radius: 100%;

    &__icon {
      font-size: var(--fs--xlarge);
      padding: 0;
      margin: var(--gap--3);
    }
    &__label {
      display: none;
      padding-left: 0;
    }
  }

  @media screen and (min-width: map-get($sections, 4)) {
    bottom: calc(env(safe-area-inset-bottom, 20px) + var(--gap--4));
  }

  @media screen and (min-width: map-get($sections, 9)) {
    .button {
      border-radius: var(--button-border-radius);

      &__icon {
        margin: var(--gap--3) var(--gap--2) var(--gap--3) var(--gap--5);
      }
      &__label {
        display: block;
      }
    }
  }
}
