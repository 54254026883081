@import '~normalize.css/normalize.css';
@import './ui/00_fundaments/fundaments.scss';

/* CSS RESET */
*,
*::before,
*::after{
    box-sizing:border-box;
}

html, body {
    margin: 0;
    padding: 0;
}

figure{
    margin:0;
}

button {
    appearance: none;
    border: none;
    cursor: pointer;
    font-size: 100%;
    font: inherit;
    line-height: inherit;
    outline: none;
}
