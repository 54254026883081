@import '../../../common/config.scss';

.overlay {
  background: var(--color--neutrals-0);
  height: auto;
  max-width: 1000px;
  padding: var(--gap--11) var(--gap--3) var(--gap--3);
  position: relative;
  width: 90vw;

  &__wrapper {
    animation: fadeIn var(--transition-time--slow) forwards;
    align-items: center;
    background: rgba(0,0,0,0.67);
    display: flex;
    flex-flow: column;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1000;
  }

  &__actions {
    padding: var(--gap--3);
    position: absolute;
    right: 0;
    top: 0;
  }

  &__content {
    position: relative;
  }

  &--video {
    .overlay__content {
      height: 0;
      padding-top: 56.26%;
    }
  }

  @media screen and (min-width: map-get($sections, 4)) {
    padding: var(--gap--10) var(--gap--9);
    width: 80%;
  }

  @media screen and (min-width: map-get($sections, 7)) {
    width: 70%;
  }
}
