@import '../../../common/config.scss';

.header {
  background: var(--color--neutrals-0);
  padding: var(--gap--2) 0;

  &__content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    // margin-top: var(--gap--6);

    @media screen and (min-width: map-get($map: $sections, $key: 3)) {
      flex-direction: row;
    }
  }

  &__logo {
      display: block;
      width: var(--gap--20);
  }

  .navigation {
    &__link {
    margin: 0;
    }

    .navigation__item {
      .button__label {
        display: flex;
        padding: var(--gap--2) var(--gap--2) calc(var(--gap--2) - 1px);
      }
    }

    // & > .button {
    //   margin-right: var(--gap--4);
    // }
  }

  &--fixed {
    // background: var(--color--neutrals-3);
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0) 76%);
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 999;

    .header__logo, .navigation {
      pointer-events: initial;
    }

    & + .page {
      margin-top: var(--header-height);
    }
  }

  &--light {
    .header__logo .logo, .navigation {
      color: var(--color--neutrals-0);
    }
  }

  &__devider{
    border-bottom: 1px solid var(--color--neutrals-0);
  }
  @media screen and (min-width: map-get($map: $sections, $key: 5)) {
    padding: var(--gap--5) 0;

    // .navigation {
    //   & > .button {
    //     margin-right: var(--gap--15);
    //   }
    // }
  }
}
