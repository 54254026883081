@import '../../../common/config.scss';

.banner {
  animation: fadeIn var(--transition-time) forwards;
  background: var(--color--neutrals-0);
  bottom: 0;
  font-size: var(--fs--small);
  opacity: 0;
  padding: var(--gap--4) 0 calc(env(safe-area-inset-bottom, 20px) + var(--gap--4));
  position: fixed;
  left: 0;
  max-height: calc(100vh - var(--header-height));
  overflow: auto;
  width: 100vw;
  z-index: 1000;

  h4 {
    margin-top: 0;
    margin-bottom: var(--gap--4);
  }

  p {
    margin-top: 0;
    margin-bottom: var(--gap--2);
  }

  &__content {
    align-items: center;
    flex-flow: column;
    justify-content: space-between;
  }

  &__meta {
    padding-right: var(--gap--5);
  }

  &__actions {
    .button {
      white-space: nowrap;
    }
  }

  &__trigger {
    bottom: calc(env(safe-area-inset-bottom, 20px) + 2 * var(--gap--20));  
    padding: 10px;
    position: fixed;
    right: -33px;
    z-index: 1000;
    transform: rotate(-90deg);

    @media screen and (min-height: 740px) {
      bottom: calc(env(safe-area-inset-bottom, 20px) + 4 * var(--gap--24));
    }

    @media screen and (min-width: 767px) {
      bottom: calc(env(safe-area-inset-bottom, 20px) + var(--gap--24));
    }

    @media screen and (min-height: 740px) and (min-width: 767px) {
      bottom: calc(env(safe-area-inset-bottom, 20px) + 4 * var(--gap--24));
    }

    @media screen and (min-height: 740px) and (min-width: 1024px) {
      bottom: calc(env(safe-area-inset-bottom, 20px) + 3 * var(--gap--24));
    }

    .button__icon {
      padding: 0;
      margin-left: 10px;
      transform: rotate(90deg);
    }
  
    .button__label {
      padding: 0;
    }
  }
  
  @media screen and (max-height: 800px) and (max-width: 375px) {
    p {
      font-size: 90%;
    }
  }

  @media screen and (min-width: map-get($sections, 2)) {
    padding: var(--gap--4);

    &__content {
      align-items: center;
      flex-flow: row nowrap;
      justify-content: space-between;
    }
  }

  // @media screen and (min-width: map-get($sections, 5)) {
  //   &__trigger {
  //     bottom: 0;
  //     left: 50%;
  //     transform: translateX(-50%);
  //   }
  // }
}

.banner--easymove,
.banner--kia_lease,
.banner--financial_renting {
  background: var(--color--primary);
  color: var(--color--text-light);

  strong {
    font-size: var(--fs--legal-normal);
    font-family: var(--ff--normal);
    // font-weight: normal;
  }
}
