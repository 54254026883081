@import '../../../common/config.scss';

@each $name, $font in $fonts {
  @font-face {
    font-family: #{$font};
    src: url('./fonts/#{map-get($fontFamilies, $name)}.woff') format('woff');
    font-weight: map-get($fontWeights, $name);
  }
}

html {
  font: normal var(--fs--text) / 1.43 var(--ff--light);
  -webkit-font-smoothing: antialiased;
}

.bold, strong {
  font-family: var(--ff--bold);
}

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.center {
  text-align: center;
}

.legal {
  font-size: var(--fs--legal-normal);
}

.heading {
  line-height: 1.45;
  margin: 0;
  padding: 0;
}

@for $i from 0 to 6 {
  h#{$i}, .heading--#{$i} {
    font-family: var(--ff--bold);
    font-size: var(--fs--h#{$i});
  }
}

@each $name, $value in $fontSizes {
  .fs--#{$name} { font-size: var(--fs--#{$name}); }
}

.p--0 {
  font-size: var(--fs--normal);
}
.p--1 {
  font-size: var(--fs--small);
}
.p--2 {
  font-size: var(--fs--xsmall);
}
.p--3 {
  font-size: var(--fs--xxsmall);
}
.p--4 {
  font-size: var(--fs--tiny);
}
.p--5 {
  font-size: var(--fs--mtiny);
}

.fs--legal {
  font-size: var(--fs--legal);
  line-height: 1.3;
}

table {
  line-height: 1.8;
}
