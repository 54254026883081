.page {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  overflow: hidden;
  position: relative;

  .header--fixed + .page__inner {
    // min-height: 100vh;
  }

  .footer {
    margin-top: auto;
  }
}
