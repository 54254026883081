@import '../../../common/config.scss';

.button {
  align-items: center;
  background: transparent;
  border: 1px solid transparent;
  border-radius: var(--button-border-radius);
  // color: inherit;
  display: inline-flex;
  font-size: var(--fs--large);
  font-family: var(--ff--medium);
  line-height: 1;
  outline: none !important;
  justify-content: center;
  padding: 0;
  text-decoration: none;
  transition: background-color var(--transition-time), border-color var(--transition-time), color var(--transition-time);
  user-select: none;

  & > *:empty {
    display: none;
  }

  &__label {
    padding: var(--gap--4) var(--gap--7) calc(var(--gap--4) - 1px);
  }

  &__icon {
    display: inline-flex;
    padding: .625em;

    &:first-child:last-child {
      padding: 0;
    }

    & + .button__label {
      padding-left: 0;
    }
  }

  &:hover, &:focus {
    text-decoration: underline;
  }
}

.button--small {
  font-size: var(--fs--xxsmall);

  .button__label {
    padding: var(--gap--3) var(--gap--6) calc(var(--gap--3) - 1px);
  }

  @media screen and (min-width: map-get($map: $sections, $key: 1)) {
    font-size: var(--fs--xsmall);
  }
}

.button--xsmall {
  font-size: var(--fs--xxsmall);

  .button__label {
    // padding: .52em 1em .5em;
    padding: var(--gap--2) var(--gap--3);
  }
}

.button--default {
  font-family: var(--ff--bold);

  &.button--primary {
    background: var(--color--primary);
    color: var(--color--text-light);

    &:hover, &:focus {
      background: var(--color--primary-hover);

    }
  }
  &.button--configuratorchoicedisabled{
    background: var(--color--neutrals-0);
    color: var(--color--primary);
  }

  &.button--configuratordisabled{
    background: var(--color--neutrals-2);
    color: var(--color--primary);

    &:hover{
      color: var(--color--neutrals-0);
      border-bottom-color: var(--color--neutrals-0);
    }
  }

  &.button--secondary {
    background: var(--color--secondary);
    color: var(--color--text-light);

    &:hover, &:focus {
      background: var(--color--secondary-hover);

    }
  }

  &.button--light {
    background: var(--color--neutrals-2);
    color: var(--color--text-dark);

    &:hover, &:focus {
      background: var(--color--neutrals-1);
    }
  }

  &[disabled] {
    background: var(--color--disabled);
    cursor: auto;
    pointer-events: none;
  }
}

.button--outline {
  background: var(--color--neutrals-0);
  border-color: var(--color--primary);

  &.button--primary {
    color: var(--color--primary);

    &:hover, &:focus {
      border-color: var(--color--primary-hover);
      color: var(--color--primary-hover);
    }
  }

  &.button--secondary {
    border-color: var(--color--secondary);
    color: var(--color--secondary);

    &:hover, &:focus {
      border-color: var(--color--secondary-hover);
      color: var(--color--secondary-hover);
    }
  }

  &.button--light {
    background: var(--color--neutrals-0);
    border-color: var(--color--primary);
    color: var(--color--text-dark);
  }

  &.button--dark {
    border-color: var(--color--text-dark);
    color: var(--color--text-dark);

    &:hover, &:focus {
      background: var(--color--text-dark);
      border-color: var(--color--text-dark);
      color: var(--color--text-light);
    }
  }
}

.button--naked {
  &.button--primary {
    color: var(--color--primary);

    &:hover, &:focus {
      color: var(--color--primary-hover);
    }
  }

  &.button--secondary {
    color: var(--color--secondary);

    &:hover, &:focus {
      color: var(--color--secondary-hover);
    }
  }

  &.button--light {
    color: var(--color--text-light);

    &:hover, &:focus {
      color: var(--color--neutrals-1);
    }
  }

  &.button--dark {
    color: var(--color--text-dark);

    &:hover, &:focus {
      color: var(--color--text-neutrals-8);
    }
  }

  .button__label {
    padding: .4em 0;
  }
}

.button--round {
  border-radius: 100%;
  line-height: 1;

  .button__label, .button__icon {
    padding: var(--gap--1);
  }
}

.button--icon-after {
  .button__icon {
    order: 2;
    padding-right: 1.25em;
  }

  .button__label {
    padding-left: 1.25em;
    padding-right: 0;
  }
}

.button--inherit {
  font: inherit;

  .button__label {
    padding: 0;
  }
}

.button--underline {
  .button__label {
    text-decoration: underline;
  }
}

.button--seethrough{
  &.button--primary {
    color: var(--color--neutrals-0);
    background: var(--color--transparent);
    border-color: var(--color--neutrals-0);

    // &:hover,
    // &:focus {
    //   color: var(--color--primary-hover);
    // }
  }
}

.button--bold{
  font-family: var(--ff--bold);
}
