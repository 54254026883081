@import '../../../common/config.scss';

.car__details--back {
  position: relative;
  margin: 0 auto;
  max-width: var(--width--section-9);
  z-index: 11;
  overflow: hidden;

  &.blend {
    mix-blend-mode: difference;
  }

  .button--back {
    pointer-events: all;
    text-shadow: var(--text-shadow);
  }

  @media screen and (min-width: map-get($sections, 4)) {
    left: var(--gap--5);
    position: absolute;
    right: var(--gap--5);
    top: calc(var(--header-height) + var(--gap--2));
    z-index: 100;

    @media screen and (min-height: 769px) {
      .button--back {
        margin-bottom: var(--gap--4);
      }
   }
  }

  @media screen and (min-width: map-get($sections, 7)) {
    animation: fadeIn var(--transition-time--slow) var(--transition-time--slow) forwards;
    opacity: 0;
    left: var(--gap--5);
    right: var(--gap--5);

    .button--back {
      display: inline-flex;
    }
  }

  @media screen and (min-width: map-get($sections, 8)) {
    padding-top: var(--gap--2);
  }
}

.car__details {
  top: 44px;
  position: relative;
  z-index: 11;
  display: none;

  .car__pricing__price__amount {
    &--monthly {
      font-size: var(--fs--small);
    }
  }

  &--back__configurator{
    position: absolute;
    top: calc(var(--header-height) + var(--gap--2));
    display: block;
    z-index: 100;
  }
  &__disclaimer{
      top: var(--header-height);
      position: relative;
      margin: 0 auto;
      max-width: var(--width--section-9);
      z-index: 11;
      overflow: hidden;
  }

  .car__pricing {
    position: relative;
    z-index: 101;

    .car__pricing__wrapper {
      align-items: flex-end;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
    }

    &__left {
      margin-right: var(--gap--2);
      width: 52%;
    }

    &__price__info {
      display: flex;
      flex-shrink: 0;
      flex-flow: row nowrap;
    }
  }

  .box__content {
    pointer-events: all;
    padding: var(--gap--2);
    display: flex;
  }

  .car__pricing__right {
    width: 100%;
  }

  .car__logo {
    display: block;
    mix-blend-mode: difference;
    width: 170px;
  }

  .car__action {
    width: 100%;
  }

  .car__price__wrapper {
    background: var(--color--neutrals-2);
    border-radius: var(--box-border-radius);
    margin-top: var(--gap--2);
    padding: var(--gap--1) var(--gap--2);
  }

  .car__price {
    margin-top: var(--gap--2);
    .priceinfo { margin-top: -.5em; }
  }

  .button--transparent {
    text-decoration: underline;
  }

  .priceinfo {
    font-size: var(--fs--small);
    vertical-align: text-top;

    .icon--alert {
      color: var(--color--primary);
    }
  }

  .page--car--open & {
    z-index: 210;

    @media screen and (max-height: 700px) {
      .car__details__disclaimer {
        top: 0;

        p { color: inherit; }
      }
    }
  }

  @media screen and (min-width: map-get($sections, 4)) {
    height: 85vh;
    left: var(--gap--5);
    margin-top: 0;
    pointer-events: none;
    position: absolute;
    right: var(--gap--5);
    top: var(--header-height);
    z-index: 102;

    .box__content {
      display: block;
    }

    &--back__configurator {
      position: absolute;
      display: block;
      z-index: 100;
    }
  }

  @media screen and (max-height: 700px) {
    position: relative;
    left: 0;
    right: 0;
    height: auto;
  }

  @media screen and (min-width: map-get($sections, 7)) {
    animation: fadeIn var(--transition-time--slow) var(--transition-time--slow) forwards;
    opacity: 0;
    height: auto;
    left: var(--gap--5);
    right: var(--gap--5);
    padding-top: var(--gap--2);
    position: fixed;
    top: calc(var(--header-height) + var(--gap--10));
    width: var(--width--section-1);
    display: block;

    .box__content {
      padding: var(--gap--2) var(--gap--5);
    }

    .car__pricing {
      &__left {
        margin-right: 0;
        width: 100%;
        margin-bottom: var(--gap--2);
      }
      .car__pricing__wrapper {
        flex-flow: column;
      }
    }

    .car__price__wrapper, .car__actions {
      margin-left: calc(-1 * var(--gap--3));
      margin-right: calc(-1 * var(--gap--3));
    }

    .car__price__wrapper {
      padding: var(--gap--2) var(--gap--3);
      margin-bottom: var(--gap--2);
    }

    .car__price {
      .popup {
        transform: translate(-50%, 0) translateX(var(--gap--19));

        &:before {
          transform: translateX(calc(-1 * var(--gap--19)));
        }
      }
    }
  }

  @media screen and (max-height: 767px) and (min-width: map-get($sections, 7)) {
    .car__price {
      .popup {
        top: 50%;
        left: 100%;
        transform: translateY(-100px) translateX(20px);

        &:before {
          border-bottom-color: transparent;
          border-right-color: var(--color--neutrals-0);
          margin-left: 0;
          transform: translateX(-100%) translateY(100px);
        }
      }
    }
  }

  @media screen and (min-width: map-get($sections, 8)) and (min-height: 900px) {
    padding-top: var(--gap--7);
  }

  @media screen and (min-width: map-get($sections, 9)) {
    left: 50%;
    transform: translateX(-920px);

    .car__price__wrapper {
      margin-bottom: var(--gap--4);
    }
  }
}
