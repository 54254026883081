@import '../../../common/config.scss';

.page--car {
  // background: var(--color--neutrals-0);
  background: linear-gradient(to bottom, var(--color--neutrals-9) 100px, var(--color--neutrals-0) 100px );
  padding-top: var(--header-height);
  position: relative;
  overflow: visible;

  .car__configurator {
    background: transparent no-repeat center center/cover;
    position: relative;
    margin: 0 auto;
    height: 70vh;
    min-height: 650px;
    max-height: 1080px;
    // max-width: 1920px;
    // margin-top: calc(var(--header-height) * -1);
    // overflow: hidden;

    &:after {
      // background: linear-gradient(to bottom, rgba(0,0,0,0), var(--color--neutrals-2) 100%);
      bottom: -1px;
      content: '';
      display: block;
      height: 20vh;
      left: 0;
      pointer-events: none;
      position: absolute;
      width: 100%;
    }

    iframe {
      border: none;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &__actions {
      z-index: 10;

      .button--model {
        width: 100%;
      }
    }
  }

  .car__help {
    background: rgba(0, 0, 0, .4);
    height: 100%;
    left: 0;
    pointer-events: all;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 101;
  }

  .car__configurator__actions {
    bottom: 0;
    position: absolute;
    left: 0;
    width: 100%;
    .car__controls{
      width: 100%;
    }
    .car__controls__content {
      display: flex;
      // flex-flow: row nowrap;
      flex-flow: column;
      justify-content: space-between;
      margin-bottom: var(--gap--2);

      @media screen and (min-width: map-get($sections, 4)){
        flex-flow: row nowrap;
      }
    }
  }

  .car__legal {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
  }

  .car__animations {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    @media screen and (min-width: map-get($sections, 4)){
      flex-flow: row nowrap;
    }

    .button {
      border-radius: 100%;
      // height: 50px;
      width: 50px;
      margin-top: var(--gap--2);
      margin-left: var(--gap--2);
      padding: var(--gap--1);

      .icon {
        height: auto;
        width: 100%;
      }

      // &:first-child {
      //   margin-left: 0;
      // }

      &:hover {
        background: var(--color--primary);
      }
    }
  }

  .car__view {
    display: flex;
    flex-flow: column;
    margin: var(--gap--2) var(--gap--2) 0 0;

    .button {
      border-bottom: 1px solid transparent;
      // border-radius: 0;

      &:hover {
        // border-bottom-color: var(--color--secondary);
        text-decoration: none;
      }

      &--primary {
        border-bottom-color: var(--color--primary);
      }
    }

    &__toggle {
      border: solid 1px var(--color--neutrals-3);
      border-radius: 50px;
      // padding: 0 var(--gap--2);
      width: 100%;
      display: flex;

      &__button {
        border-radius: 50px;
        padding: 0 0 0 10px;
        flex-grow: 1;
      }
    }

    .icon {
      height: auto;
      padding-bottom: 0;
      padding-top: 0;
      width: var(--gap--6);
    }

    .button__label {
      padding: var(--gap--2) var(--gap--3);
      padding-left: 0;
    }

    .button__icon {
      padding: 0 var(--gap--2);
    }
  }
  .car__links{
    display: flex;
    flex-direction: row;
  }
  .car__colors {
    // align-items: center;
    display: flex;
    flex-flow: row wrap;
    // justify-content: center;
    margin-bottom: 0;
    margin-left: calc(-1 * var(--gap--1));
    margin-right: calc(-1 * var(--gap--1));
    margin-top: var(--gap--1);
    text-align: center;

    &:after {
      content: "";
      flex: auto;
    }

    &__title {
      display: flex;
      flex-flow: row nowrap;
    }

    .color {
      background: var(--color--neutrals-2);
      border-radius: 100%;
      cursor: pointer;
      font-size: var(--fs--xsmall);
      height: 30px;
      margin: 0 calc(var(--gap--1) / 2) var(--gap--1);
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover,
      &--active {
        border: 1px solid var(--color--primary);
      }

      &__swatch {
        border-radius: 100%;
        box-shadow: 0 0 1px rgba(0,0,0,0.4);
        height: 70%;
        position: relative;
        width: 70%;

        &:after {
          // background:
          //   radial-gradient(ellipse at left bottom, rgba(255, 255, 255, .4), transparent) left 30% no-repeat,
          //   linear-gradient(45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%),
          //   linear-gradient(165deg, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.4) 47%, rgba(0, 0, 0, 0.2) 47.1%, rgba(0, 0, 0, 0.1) 50%, transparent 50.1%);
          content: '';
          display: block;
          height: 100%;
          width: 100%;
        }
      }

      &:hover, &--active {
        .color__swatch {
          box-shadow: none;
        }
      }
    }

    &__content--scroll {
      align-items: center;
      display: flex;
      flex-flow: row nowrap;

      .car__colors {
        flex-flow: row nowrap;
        overflow: auto;
        margin-left: var(--gap--1);
        margin-right: var(--gap--1);
      }

      .color {
        flex-shrink: 0;
      }

      .button {
        height: 30px;
        flex-shrink: 0;
        width: 30px;

        &[disabled] {
          background: transparent;
        }
      }
    }
  }

  .car__content {
    color: var(--color--text--dark);
    margin-left: var(--gap--5);
    margin-right: var(--gap--5);
    margin-top: var(--gap--5);
    max-width: var(--width--section-7);
    padding-bottom: var(--gap--5);
    position: relative;
    // z-index: 10;

    &__description {
      width: 100%;

      // &__wrapper{
      //   width: 110%;
      //   background: var(--color--neutrals-2);
      // }
    }

    &__wrapper{
      overflow: hidden;
    }

    .button__label__underlined {
      font-family: var(--ff--bold);
      margin-left: 0;
      padding-left: 0;
      color: var(--color--text--dark);
      background: var(--neutrals-0);
      text-decoration: underline;

      .button__label {
        font-family: var(--ff--bold);
        margin-left: 0;
        padding-left: 0;
      }
    }
  }

  .car__scrolltriggers {
    align-items: center;
    color: var(--color--text-dark);
    display: none;
    // display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    left: 0;
    position: absolute;
    top: -200px;
    transform: translateY(-100%);
    width: 100%;

    .scrolltrigger {
      display: flex;
      flex-flow: column;
      text-shadow: var(--text-shadow--light);
      transition: color var(--transition-time);

      .button__icon {
        padding: var(--gap--1);
      }

      .icon {
        font-size: var(--fs--icon);
        filter: drop-shadow( var(--text-shadow--light));
      }

      .button__label {
        font-family: var(--ff--bold);
      }
    }
  }

  .car__powertrains {
    align-items: stretch;
    background: var(--color--neutrals-0);
    display: flex;
    flex-flow: column;
    gap: var(--gap--4);
    justify-content: flex-start;
  }

  .car__powertrains__devider{
    border-bottom: 1px solid var(--color--neutrals-9);
  }

  .car__stocklocator{
    text-align: center;
  }

  .loading__wrapper {
    background: var(--color--neutrals-0);
    align-items: center;
    display: flex;
    height: 100%;
    flex-flow: column;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;

    &.hidden {
      animation: fadeOut var(--transition-time--slow) forwards;
    }

    .loading__preview {
      background: transparent no-repeat center center/cover;
      display: block;
      height: 100%;
      width: 100%;
    }
    .loading__video {
      display: block;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .car__legal {
    display: flex;
    // flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    &__column{
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      flex: 1;
    }

    &__devider{
      width: 100%;
      border-top: 1px solid var(--color--neutrals-3);
    }

    &__wrapper{
      margin-bottom: var(--gap--10);
    }
    img {
      display: inline-flex;
      margin-right: var(--gap--5);
      width: 100px;
      align-self: flex-start;
    }
    p {
      display: inline-flex;
      margin: var(--gap--2) 0;
    }

    &__column {
      p {
        display: block;
      }
    }

    &--warranty {
      display: flex;
      flex-flow: row nowrap;
      margin: var(--gap--2) 0;
    }
  }

  .car__buttons__prices {
    margin-right: var(--gap--2);

    .car__buttons__price {
      justify-content: left;

      &:first-of-type {
        margin-bottom: 0;
      }

      &__amount {
        white-space: nowrap;
      }
    }
  }

  &.page--car--open {
    // padding-bottom: var(--gap--10);
    position: relative;
    overflow: hidden;

    .page__inner {
      display: flex;
      flex-flow: column;
      height: calc(100vh - 29px);
      overflow: hidden;
      margin-top: calc(-1 * var(--header-height));
      overflow: hidden;
    }

    .car__configurator {
      // height: 70vh;
      // margin-bottom: auto;
      flex-grow: 1;
      max-height: none;
      max-width: none;
      width: 100%;
    }

    .car__configurator--loaded {
      //   height: calc(100vh - 127px - 29px); // 127px = button bar, 29px = footer
      //   margin-top: calc(var(--header-height) * -1);

      iframe {
        height: 75%;
      }
    }
  }

  &:not(.page--car--open) {
    .car__configurator {
      z-index: 250;
    }
    .footer {
      position: absolute;
      bottom: var(--gap--10);
    }
  }

  @media screen and (max-width: 767px) {
    &--interior {
      .car__pricing__wrapper, .car__colors__content {
        display: none !important;
      }

      // .car__configurator {
      //   overflow: hidden;
      //   margin-top: 0;
      //   height: 75vh;

      //   iframe {
      //     top: -15vh;
      //   }
      // }
    }
    .car__content_wrapper{
      display: flex;
      justify-content: center;
    }
  }

  @media screen and (max-width: 1023px) {
    &.page--car--open {
      .car__buttons__price__infobutton {
        .popup {
          bottom: 100%;
          display: flex;
          flex-flow: column;
          top: auto;

          &:before {
            border-bottom-color: transparent;
            border-top-color: var(--color--neutrals-0);
            margin-top: 0;
            order: 2;
            transform: translateX(calc(-1* var(--gap--19)));
          }
        }
      }
    }
  }

  // @media screen and (min-height: 600px) {
  //   &:not(.page--car--open) {
  //     .car__configurator {
  //       margin-top: -5vh;
  //       min-height: 520px;
  //     }
  //   }

  // }

  @media screen and (min-height: 570px) and (min-width: 1024px) {
    &:not(.page--car--open) {
      .car__configurator {
        min-height: 650px;
        height: 80vh;
        // margin-top: -5vh;
      }
    }

  }

  @media screen and (min-height: 800px) {
    &:not(.page--car--open) {
      .car__configurator {
        height: 80vh;
      }
    }

  }

  @media screen and (min-width: map-get($sections, 4)) and (min-height:570px) {
    .logo {
      color: var(--color--text-light);
    }
    .navigation {
      color: var(--color--text-light);
    }
  }
  @media screen and (min-width: map-get($sections, 4)) {
    padding-bottom: 0;
    padding-top: 0;

    &:not(.page--car--open) {
      .footer {
        bottom: 0;
      }
    }

    &.page--car--open {
      padding-bottom: 0;

      .page__inner {
        height: calc(100vh - 31px);
        margin-top: 0;
      }

      .car__configurator {
        iframe {
          height: 100%;
        }
      }
    }

    // .car__configurator {
    //   // height: 83vh;
    //   // margin-top: 0;
    //   // z-index: 1;

    //   // #configurator {
    //   //   margin-top: 0;
    //   // }
    // }

    .car__view {
      flex-flow: row nowrap;
      margin-bottom: var(--gap--2);
      margin-top: var(--gap--2);

      .button {
        width: 50%;
      }

      &__toggle{
        border: solid 1px var(--color--neutrals-3);
        border-radius: 50px;
        // padding: 0 var(--gap--2);
        width: 100%;

        &__button {
          border-radius: 50px;
          padding: 0 0 0 10px;
        }
      }


    }

    .car__colors {
      margin-top: var(--gap--2);

      &__wrapper {
        margin-top: var(--gap--2);
        width: 50%;

      }
      &__title {
        flex-flow: column;
      }
    }

    .car__legal__wrapper {
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: map-get($sections, 4)) {
    .configurator__controls {
      align-items: flex-end;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
    }

    .car__help {
      .info {
      transform: translate(-50%, -50%);
      }
    }

    .car__powertrains {
      flex-flow: row wrap;
      margin-left: calc(-1 * var(--gap--2));
      margin-right: calc(-1 * var(--gap--2));
      background: var(--color--neutrals-0);

      .powertrain__wrapper {
        margin: var(--gap--2) 0;
        width: calc(50% - var(--gap--4));
      }
    }
  }

  @media screen and (min-width: map-get($sections, 7)) {

    &.page--car--open {
      .car__configurator {
        height: calc(100vh - 117px - 31px + var(--header-height));
        width: 100%;
      }
    }

    .car__configurator__actions {
      animation: fadeIn var(--transition-time--slow) var(--transition-time--slow) forwards;
      opacity: 0;
      position: relative;

      .car__controls {
        flex-flow: column;
      }
    }

    .configurator__controls {
      align-items: flex-start;
      flex-flow: column;
    }

    .car__view {
      margin-left: 0;

      .button--primary {
        pointer-events: none;
      }
    }

    .car__colors {
      margin-left: calc(-1 * var(--gap--2));
      margin-right: calc(-1 * var(--gap--2));
      margin-top: var(--gap--2);
      justify-content: space-between;

      &__wrapper {
        width: 100%;
      }

      .color {
        height: 28px;
        margin: var(--gap--1);
        width: 28px;
      }
    }

    .car__content {
      animation: fadeIn var(--transition-time--slow) var(--transition-time) forwards;
      margin-left: 20%;
      margin-right: 20%;

      opacity: 0;
    }

    .car__scrolltriggers {
      display: flex;
      top: calc(-1 * var(--gap--5));

      &--loading {
        .scrolltrigger {
          color: var(--color--neutrals-2);
          text-shadow: var(--text-shadow);
        }
      }
    }


  }

  @media screen and (max-height: 799px) and (max-width: map-get($sections, 7)) {
    .car__configurator__actions {
      position: absolute;
    }
  }

  @media screen and (max-height: 799px) and (min-width: map-get($sections, 7)) {
    .car__configurator__actions {
      bottom: 0;
      left: auto;
      // margin-top: 0;
      top: auto;
      width: 100%;

      // &.show-actions {
      //   position: absolute;
      //   right: calc(-100vw + 100% + var(--gap--18));
      // }
    }
  }

  @media screen and (min-width: map-get($sections, 9)) {
    .car__configurator__actions {
      position: relative;
    }
    .car__content {
      margin-left: auto;
      margin-right: auto;
      width: calc(100vw - var(--gap--5) - var(--width--section-0) - var(--gap--5) - var(--width--section-0));
    }

  }
}

