@import '../../../common/config.scss';

.navigation {
  align-items: baseline;
  display: flex;
  flex-flow: row nowrap;
  flex-wrap: wrap;
  justify-content: center;

  @media screen and (min-width: map-get($map: $sections, $key: 3)) {
    justify-content: right;
  }

  @media screen and (min-width: map-get($map: $sections, $key: 4)) {
    flex-wrap: nowrap;
  }

  &__container {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    // flex-wrap: wrap;

    & > .navigation__buttons__devider {
      &:nth-child(1) {
        display: none;

        @media screen and (min-width: map-get($map: $sections, $key: 4)) {
          display: block;
        }
      }
    }

    &__button {
      padding: 0 calc(var(--gap--2) + 4px);
      position: relative;
      white-space: nowrap;

      &:after {
        content: '';
        width: 1px;
        height: 12px;
        background-color: white;
        position: absolute;
        right: 0;
      }

      &.no-right-border {
        padding-right: 0;

        &:after {
          display: none;
        }
      }

      @media screen and (min-width: map-get($map: $sections, $key: 4)) {
        &:before {
          content: '';
          width: 1px;
          height: 12px;
          background-color: white;
          position: absolute;
          left: 0;
        }
      }
    }

    &__language {
      margin-top: 5px;
    }
  }

  &__b2bb2c {
    margin-top: 5px;
    order: 1;
    display: block;
    width: 100%;
    text-align: center;
    text-transform: capitalize;
    
    @media screen and (min-width: map-get($map: $sections, $key: 2)) {
      text-transform: lowercase;
    }

    @media screen and (min-width: map-get($map: $sections, $key: 3)) {
      text-align: right;
    }

    @media screen and (min-width: map-get($map: $sections, $key: 4)) {
      margin-top: 0;
      order: initial;
      width: auto;
      text-align: left;
      white-space: nowrap;
    }

    &__switch {
      display: inline-block;
    }

    &__toggle__prefix {
      display: none;
      
      @media screen and (min-width: map-get($map: $sections, $key: 2)) {
        display: inline;
        text-transform: capitalize;
      }
    }
  }

  &__buttons__devider {
    color: var(--color--text-light);
  }

  &__list {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__link {
    display: block;
    margin: 0 var(--gap--1);
    position: relative;
    text-decoration: none;
    transition: color var(--transition-time);

    &.button--outline {
      &:not(.navigation__link--active) {
        background: transparent;
        border: none;
        text-shadow: var(--text-shadow);
      }
    }

    &:hover {
      &:before {
        background: var(--color--neutrals-0);
      }
      &.button {
        color: var(--color--neutrals-2);
      }
    }

    &--active {
      &:before {
        background: var(--color--neutrals-0);
      }

      &.button {
        color: var(--color--primary);
        pointer-events: none;
      }
    }
  }

  & > .button {
    // margin: 0 var(--gap--1);
    text-align: center;
  }

  &__banner {
    font-size: 12px;
    color: #ffffff;
    padding: 10px 0 0;
    text-align: center;
    
    @media screen and (min-width: map-get($map: $sections, $key: 3)) {
      text-align: right;
    }
    
    @media screen and (min-width: map-get($map: $sections, $key: 4)) {
      text-align: left;
      padding: 0 var(--gap--5);
    }
    
    @media screen and (min-width: map-get($map: $sections, $key: 7)) {
      position: absolute;
      left: 20%;
      font-size: 14px;
      padding: 0;
      line-height: 34px;
      text-align: left;
    }
  }
}
