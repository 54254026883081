@import '../../../common/config.scss';

.carpreview {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  position: relative;
  // z-index: 100;

  &.hide {
    animation: fadeOut var(--transition-time--slow) forwards;
  }

  img {
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    object-position: 46% center;
  }

  &__engines {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    .engine {
      margin-left: calc(var(--gap--1) / 2);
      margin-right: calc(var(--gap--1) / 2);
      // width: 80px;
    }
  }

  &__visual {
    height: 100%;
    display: block;
    // margin-top: 4vh;
    width: 100%;
    // transform: translate(-2%, 34%) scale(2);

    &__overlay{
      // background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 15%, rgba(255, 255, 255, 0) 100%);
      height: 100%;
      position: relative;
      width: 100%;
      overflow: hidden;

      &:before {
        background: linear-gradient(0deg, rgba(0, 0, 0, .4) 15%, rgba(255, 255, 255, 0) 100%);
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 20%;
        pointer-events: none;
      }

      &:after {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 40%, rgba(255, 255, 255, 0) 100%);
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 70%;
        pointer-events: none;
      }
    }
  }

  &__carname {

    font: var(--fs--h4) / normal var(--ff--medium);
    text-align: center;
    margin: var(--gap--3) 0;
    white-space: nowrap;
    // text-shadow: var(--text-shadow);
    // text-transform: uppercase;
  }

  &__price {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    transform: translateY(50%);
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
    font: var(--fs--small) / 1.5 var(--ff--medium);
    padding-left: 8px;
    border-left: 3px solid var(--color--text-light);

    &__easymove {
      align-items: center;
      display: flex;
      font: var(--fs--small) / normal var(--ff--bold);
    }

    &__name{
      color: var(--color--text-light);
      white-space: nowrap;
    }

    &__amount {
      white-space: nowrap;
      font: var(--fs--small) / normal var(--ff--medium);
    }

    &__info &__infobutton {
      display: inline;

      .priceinfo {
        vertical-align: unset;

        .button__label {
          font-size: var(--fs--small);
          padding: 0;
        }
      }
    }

    &__infobutton{
           display: flex;
             align-items: center;
            justify-content: center;
          }

          &__infobutton .icon{
            width: 0.7em;
            // height: 0.8em;
           color: white;
            margin-left: var(--gap--3);
          }
  }

  &__content {
    color: var(--color--text-light);
    left: 50%;
    position: absolute;
    top: 160px;
    transform: translateX(-50%);
    // width: var(--car--logo-width);
    z-index: 102;

    @media screen and (min-width: map-get($map: $sections, $key: 1)) {
      top: 140px;
    }

    @media screen and (min-width: map-get($map: $sections, $key: 2)) {
      top: 140px;
    }

    @media screen and (min-width: map-get($map: $sections, $key: 3)) {
      top: 100px;
    }

    @media screen and (min-width: map-get($map: $sections, $key: 4)) {
      top: 140px;
    }

    @media screen and (min-width: map-get($map: $sections, $key: 5)) {
      top: 16vh;
    }

    @media screen and (min-width: map-get($map: $sections, $key: 5)) and (max-height: 630px) {
      top: 99px;
    }

    &__prices {
      position: relative;

      .pricebox {
        display: inline-block;
        vertical-align: top;

        & + .pricebox {
          margin-left: 30px;
        }
      }
    }
  }

  &__arrows {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    position: absolute;
    top: 175px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;

    @media screen and (min-width: map-get($map: $sections, $key: 1)) {
      top: 155px;
    }

    @media screen and (min-width: map-get($map: $sections, $key: 2)) {
      top: 50%;
    }

    &__background{
      background: transparent;
      border-radius: 100px;
      padding: 10px;
    }

    .button {
      font-size: 2.375em;
    }
  }

  &__actions {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: var(--gap--2);

    // width: 100%;
    z-index: 100;
    overflow: hidden;

    &__wrapper{
      left: 6%;
      position: absolute;
      top: 73%;
    }

    &__conditionsbutton{
      border-color: transparent;
    }

    .button__icon {
      .icon {
        height: 1.2em;
        width: 1.2em;
        margin-right: -2px;
      }
    }

    .button__label {
      padding-left: var(--gap--3);
      padding-right: var(--gap--3);
    }
  }

  &__badge {
    display: flex;
    height: 3em;
    margin: var(--gap--2) auto;
    width: 3em;

    img {
      height: auto;
      object-fit: contain;
      object-position: center center;
      width: 100%;
    }
  }

  .car__short__description{
    position: absolute;
    top: auto;
    overflow: hidden;
    display: flex;
    // color: var(--color--neutrals-0)

    p {
      margin-top: 0;
      margin-bottom: 1.5em;
    }
  }

  @media screen and (min-width: map-get($sections, 2)) {

    &__arrows {
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90vw;

      &__background {
        background: var(--color--transparent);
        border-radius: 100px;
        padding: 10px;
      }

      .button {
        font-size: 2.375em;
      }
    }
    &__actions__wrapper {
      left: 11%;
    }

    // &__visual {
    //   transform: translate(0%, 49%) scale(3.5);
    // }
    // &__content {
    //   color: var(--color--text-light);
    //   left: 0%;
    //   transform: translateX(0%);
    //   margin-left: calc(var(--gap--5) + var(--width--section-1));
    // }
  }

  @media screen and (min-width: map-get($sections, 3)){
    // &__visual {
    //   transform: translate(4%, 9%) scale(2.6);
    // }
  }

  @media screen and (min-width: map-get($sections, 4)) {

    // &__visual {
    //   img {
    //     object-position: center center;
    //   }
    // }

    &__actions__wrapper{
      left: 8%;
    }
    .car__short__description {
      margin-left: 20%;
      max-width: 35%;
      bottom: 15%;
    }

    // &__visual {
    //   transform: translate(6%, 11%) scale(3);
    // }
  }

  // @media screen and (min-height: 700px){
  //   .car__short__description {
  //     top: 60%;
  //   }

  // }
  @media screen and (min-width: map-get($sections, 5)) {
    &__content {
      animation: fadeIn var(--transition-time--slow) var(--transition-time) forwards;
      opacity: 0;
      color: var(--color--text-light);
      left: 0%;
      transform: translateX(0%);
      margin-left: 20%;
    }

    &__actions {
      animation: fadeIn var(--transition-time--slow) var(--transition-time--slow) forwards;
      opacity: 0;
      justify-content: start;

      &__wrapper{
        margin-left: 20%;
        margin-right: var(--gap--20);
        left: 0;
        // top: 85%;
        top: auto;
        bottom: 0;
      }
    }

    .car__short__description {
      margin-left: 20%;
      max-width: 20%;
      // top: 58%;
    }

    &__engines {
      justify-content: start;
    }

    // &__visual {
    //   transform: translate(2%, 11%) scale(3);
    // }

    &__price {
      justify-content: start;
    }

    &__carname {
      font-size: calc(var(--fs--hero) * 2);
      text-align: left;
    }

    &__badge {
      display: inline-flex;
      height: 1.4ch;
      margin-left: var(--gap--3);
      width: 1.4ch;
    }
  }
  // @media screen and (min-width: ($sections, 6)) {
  //   &__visual {
  //     transform: translate(1%, -7%) scale(2.7);
  //   }
  // }

  @media screen and (min-width: 1024px) {
    // &__visual {
    //   transform: translate(2%, -8%) scale(2.4);
    // }
    .car__short__description {
      margin-left: 20%;
      max-width: 30%;
      // top: 68%;
    }

    &__visual {
      img {
        width: 150%;
        object-position: right 60%;
      }
    }

    // &__visual {
    //   img {
    //     object-position: bottom right;
    //     height: 130%;
    //     width: 150%;
    //   }

    //   &__overlay {
    //     &:after {
    //       height: 55%;
    //     }
    //   }
    // }

    @media screen and (max-height: 700px) {
      // &__visual {
      //   img {
      //     height: 140%;
      //   }
      // }
    }
  }

  // @media screen and (min-width: 1080px) {
  //   // &__visual {
  //   //   transform: translate(2%, -9%) scale(2.2);
  //   // }
  //   .car__short__description{
  //     // margin-left: 20%;
  //     max-width: 40%;
  //     // top: 68%;
  //   }
  // }
  @media screen and (min-width: 1280px) {
    // &__visual {
    //   transform: translate(7%, -23%) scale(1.2);
    // }
    .car__short__description{
      margin-left: 20%;
      max-width: 30%;
      // top: 68%;
    }

    &__visual {
      // img {
      //   object-position: bottom right;
      //   height: 120%;
      //   width: 150%;
      // }

      &__overlay {
        &:after {
          height: 50%;
        }
      }
    }

  }

  @media screen and (min-width: 1280px) and (max-height: 700px) {
    &__visual {
      // img {
      //   height: 150%;
      // }

      &__overlay {
        &:after {
          height: 45%;
        }
      }
    }
  }

  &__b2c__loan {
    display: none;

    @media screen and (min-width: 1024px) {
      display: block;
      font-size: var(--fs--xxsmall);
      margin-top: 0;
    }
  }
}
