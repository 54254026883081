@import '../../../common/config.scss';

.languages {
  &__list {
    display: flex;
    flex-flow: column;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    margin: var(--gap--4);
  }

  @media screen and (min-width: map-get($sections, 4)) {
    &__list {
      flex-flow: row nowrap;
    }
  }
}
