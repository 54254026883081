@import "../../../common/config.scss";

.promo {
  background: var(--color--primary);
  color: var(--color--text-light);
  position: relative;

  p {
    margin: 0;
  }

  &__wrapper {
    &.show-border {
      border: 3px solid var(--color--text-light);
      padding: var(--gap--4);
    }

    &.show-close {
      padding-top: var(--gap--8);
    }
  }

  &__content {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  &__footer {
    align-items: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-top: var(--gap--3);
  }

  &__close {
    position: absolute;
    right: var(--gap--8);
    top: var(--gap--8);
  }

  &__trigger {
    cursor: pointer;
    transform: rotate(180deg);
    transition: transform var(--transition-time--fast) ease-in-out;

    .is-open & {
      transform: rotate(0);
    }
  }

  &__legal {
    display: none;
    font-size: var(--fs--xsmall);

    .is-open & {
      display: block;
    }
  }

  .button.button--outline {
    margin-left: var(--gap--5);
    text-align: center;
  }
}
