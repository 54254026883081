@import './colors/colors.scss';
@import './typography/typography.scss';
@import './boxing/boxing.scss';
@import './animations/animations.scss';

:root {
  @each $name, $color in $colors {
    --color--#{$name}: #{$color};
  }

  @each $name, $font in $fonts {
    --ff--#{$name}: #{$font}, sans-serif;
  }

  @each $name, $size in $fontSizes {
    --fs--#{$name}: #{$size};
  }

  @each $name, $value in $sections {
    --width--section-#{$name}: #{$value};
  }

  @for $i from 0 to $gaps {
    --gap--#{$i}: #{$i * $gap};
  }

  --width--max: #{$max-width};

  --transition-time--fast:   #{$transition-time-fast};
  --transition-time:        #{$transition-time};
  --transition-time--slow:  #{$transition-time-slow};

  --header-height: 44px;

  --box-border-radius: #{$box-border-radius};
  --button-border-radius: #{$button-border-radius};
  --input-border-radius: #{$input-border-radius};
  --color-border-radius: #{$color-border-radius};
  --config-option-border-radius: #{$config-option-border-radius};

  --box-shadow: 0 0 9px -4px rgba(5,20,31,0.65);
  // --text-shadow: 0 0 5px rgba(0,0,0,0.7);
  --text-shadow: 2px 1px 0 rgba(0,0,0,0.7);
  --text-shadow--light: 0 0 5px rgba(255, 255, 255, .4);

  --fs--legal: var(--fs--legal-min);

  --car--logo-width: 150px;

  @media screen and (min-height: 175px) {
    --fs--legal: var(--fs--legal-max);
  }

  @media screen and (min-width: map-get($sections, 4)) {
    --car--logo-width: 230px;
    --header-height: #{$header-height};
  }
  @media screen and (min-width: map-get($sections, 5)) {
    --car--logo-width: 250px;
  }

  // if reduced motion is prefered, set transition time to 0
  @media screen and (prefers-reduced-motion: reduce) {
    --transition-time--fast: 0s;
    --transition-time: 0s;
    --transition-time--slow: 0s;
  }
}
