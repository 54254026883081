.eswitch {
  --ball-radius: var(--gap--4);

  align-items: center;
  background: var(--color--primary);
  border-radius: var(--button-border-radius);
  color: var(--color--text-light);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: var(--gap--3) var(--gap--5);
  position: relative;
  width: 260px;

  &:after {
    // box-shadow: 0 0 20px var(--color--electric);
    border-radius: inherit;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity var(--transition-time) ease-in-out;
    width: 100%;
    z-index: -1;
  }

  &__logo {
    color: inherit;
    pointer-events: none;
    width: 180px;
  }

  &__toggle {
    background: var(--color--neutrals-6);
    border: 1px solid var(--color--neutrals-1);
    border-radius: var(--ball-radius);
    cursor: pointer;
    flex-shrink: 0;
    height: calc(var(--ball-radius) + 2px);
    margin-left: calc((var(--ball-radius) / 2) + var(--gap--2));
    position: relative;
    transition: background-color var(--transition-time);
    width: calc(var(--ball-radius) * 2.5);

    span {
      background: var(--color--neutrals-0);
      // border: 1px solid var(--color--neutrals-6);
      border-radius: 100%;
      height: var(--ball-radius);
      left: -2px;
      position: absolute;
      top: 50%;
      transform: translate3d(2px, -50%, 0);
      transition: transform var(--transition-time), background-color var(--transition-time);
      width: var(--ball-radius);
    }
  }

  &--active {
    .eswitch__toggle {
      // background: var(--color--neutrals-9);

      span {
        // background: var(--color--electric);
        transform: translate3d(-50%, -50%, 0) translateX(calc((var(--ball-radius) * 2) - 2px));
        left: 0;
      }
    }

    &:after {
      opacity: 1;
    }
  }
}
