@import '../../../common/config.scss';

.car__button__details{
  width: 100%;
  margin-left: 0;
  left: 0;
  right: 0;
  position: sticky;
  top: -1px;
  z-index: 200;

  .car__buttons{
    background-color: var(--color--primary);
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: center;


    &__price{
      display: flex;
      align-items: center;
      justify-content: center;
      // margin-bottom: var(--gap--2);

      &__infobutton {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &__infobutton .icon {
        color: white;
        margin-left: var(--gap--2);
      }

      &__wrapper{
        display: flex;
        justify-content: space-around;
        width: 100%;
        align-items: center;

        @media screen and (max-width: 767px) {
          margin-bottom: var(--gap--2);
        }
      }
    }
    &__price__name{
      color: var(--color--secondary);
      font-size: var(--fs--small);
      white-space: nowrap;
    }

    &__price__amount{
      color: var(--color--neutrals-0);
      font-size: var(--fs--h4);

      &--monthly {
        font-size: var(--fs--small);
      }
    }
  }

  .car__buttons__actions {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    grid-column-start: 2;
    margin: 2px;
  }

  .car__button__action{
    padding: var(--gap--4) 0 var(--gap--2) 0;
  }

  .priceinfo__easymove,
  .priceinfo__kia_lease,
  .priceinfo__financial_renting {
    display: inline;
    color: var(--color--primary);
  }

  .car__buttons__carname{
    font-size: var(--fs--h4);
    margin: 0 var(--gap--2) 0 0;
  }

  @media screen and (min-width: 767px) {
    .car__buttons__wrapper {
      // display: grid;
      // grid-template-columns: 3fr auto 1fr;
      display: flex;
      flex-flow: column;
      gap: var(--gap--2);
    }

    .car__buttons__price {
      margin-bottom: 0;
    }

    .car__buttons__price__wrapper {
      justify-content: center;
      // margin-right: var(--gap--5);
      width: auto;
    }
  }

  @media screen and (min-width: 1024px) {
    .car__button__action {
      // padding: 0 var(--gap--8);
      padding-left: var(--gap--8);
      padding-right: var(--gap--8);
    }

    .car__buttons__wrapper {
      // grid-template-columns: 1fr auto 1fr;
      flex-flow: row nowrap;
      justify-content: center;
      gap: var(--gap--5);
    }
  }

  @media screen and (min-width: 1840px){
    transform: translateX(0px);

  }

  @media screen and (min-width: 1300px) and (min-height: 900px) {
    padding-top: 0;
  }
  .car__button__details .car__button__action{
    width: auto;
  }

  .car__button__action{
    display: flex;
    flex-flow: column;
    color: var(--color--neutrals-0);
    background-color: var(--color--neutrals-8);

    border: 0.5px solid transparent;
    border-color: var(--color--primary);
    text-align: center;
    // width: auto;
    // flex: 1;

    .button__icon{
      .icon {
        height: 4em;
        width: 4em;
      }
      padding: 0;
    }

    .button__label{
      padding-top: 0px;
      font-family: var(--ff--bold);
    }
  }

}


