@import '../../../common/config.scss';

body {
    color: var(--color--text-dark);

    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color:  transparent;
}

a { color: inherit; }

@each $name, $color in $colors {
  .fc--#{$name}{ color: var(--color--#{$name}); }
  .bg--#{$name}{ background-color: var(--color--#{$name}); }
}

table {
  td, th {
    border-bottom: 1px solid var(--color--neutrals-3);
  }

  tr:last-child {
    td, th {
      border-bottom: none;
    }
  }
}

