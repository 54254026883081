.form__input {
  display: block;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.form__input {
  &[type="checkbox"], &[type="radio"] {
    height: 1px;
    left: -9999px;
    opacity: .01;
    position: absolute;
    top: -9999px;
    width: 1px;

    & + label {
      align-items: center;
      display: inline-flex;

      &:before {
        background: var(--color--neutrals-0);
        border: 1px solid var(--color--neutrals-4);
        // border-radius: 4px;
        color: var(--color--primary);
        content: '';
        cursor: pointer;
        display: inline-flex;
        flex-shrink: 0;
        height: 17px;
        line-height: 1.25;
        margin-right: var(--gap--2);
        padding: 0 .2em;
        width: 17px;
      }
    }

    &:checked + label {
      &:before {
        // background: var(--color--primary);
        border-color: var(--color--primary);
        content: '✓';
      }
    }
  }
}
