@import '../../../common/config.scss';

.allcarsswitch {
  // bottom: 0;
  // box-shadow: var(--box-shadow);
  display: flex;
  flex-flow: column;
  // left: 50%;
  max-height: 75vh;
  pointer-events: all;
  // position: fixed;
  // transform: translate3d(-50%, 100%, 0);
  // transition: transform var(--transition-time), background-color var(--transition-time), color var(--transition-time);
  // width: calc(100vw - var(--gap--5) - var(--gap--5));
  z-index: 1;
  display: none;

  // &:before {
  //   background: rgba(0, 0, 0, 0.67);
  //   bottom: 0;
  //   height: 100vh;
  //   // left: 50%;
  //   opacity: 0;
  //   position: absolute;
  //   // transform: translateX(-50%);
  //   width: 100vw;
  // }

  // &__toggle {
  //   background: var(--color--primary);
  //   border-radius: var(--box-border-radius) var(--box-border-radius) 0 0;
  //   color: var(--color--text-light);
  //   cursor: pointer;
  //   left: 50%;
  //   padding: .4em 2em calc(env(safe-area-inset-bottom, 20px) + .4em);
  //   position: absolute;
  //   text-align: center;
  //   top: 0;
  //   // transform: translate3d(-50%, -100%, 0);
  //   transition: background-color var(--transition-time);
  //   white-space: nowrap;

  //   &:hover,
  //   &:focus {
  //     background: var(--color--primary-hover);
  //   }

  //   .icon {
  //     height: auto;
  //     margin-left: var(--gap--2);
  //     transform: rotate(180deg);
  //   }

  //   .open & {
  //     .icon {
  //       transform: none;
  //     }
  //   }
  // }

  &__content {
    align-items: stretch;
    background: var(--color--neutrals-0);
    border-radius: var(--box-border-radius) var(--box-border-radius) 0 0;
    color: var(--color--text-medium);
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    padding: var(--gap--1);

    flex-shrink: 1;
    height: auto;
    overflow: auto;
    position: relative;
    z-index: 1;
  }

  &__item {
    align-items: stretch;
    border: 1px solid transparent;
    border-radius: var(--box-border-radius);
    color: inherit;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    margin: var(--gap--1);
    max-width: 100px;
    opacity: .5;
    padding: var(--gap--1);
    text-decoration: none;
    transition: color var(--transition-time), border-color var(--transition-time), opacity var(--transition-time);
    width: calc(50% - var(--gap--2));

    &:hover {
      color: var(--color--text-dark);
      opacity: 1;
    }

    &.allcarswitch__item--active {
      background: var(--color--neutrals-2);
      color: var(--color--text-dark);
      opacity: 1;
      pointer-events: none;

      &.allcarswitch__item--electric {
        border-color: var(--color--electric);
      }
    }
  }

  &__visual {
    img {
      height: var(--fs--icon);
      object-fit: contain;
    }
  }

  &__title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .eswitch {
    border-radius: 0;
    justify-content: center;
    width: 100%;

    &:after {
      content: initial;
    }

    &__logo {
      margin-right: var(--gap--4);
      width: 96px;
    }
  }

  &.open {
    display: block;
    // transform: translate3d(-50%, 0, 0);

    // &:before {
    //   animation: fadeIn 1000ms 300ms forwards;
    //   content: '';
    // }

    // .allcarswitch__toggle {
    //   padding: .4em 2em;
    // }
  }

  &.allcarswitch--electric {
    .allcarswitch__content {
      // background-color: var(--color--neutrals-8);
      // color: var(--color--text-light);
    }

    .allcarswitch__item {
      //   background: transparent;
      //   color: var(--color--text-light);

      &--electric {
        border-color: var(--color--electric-dark);
        opacity: .8;

        &:hover {
          border-color: var(--color--electric);
        }
      }

      &--active {
        opacity: 1 !important;
      }
    }

    // .eswitch {
    //   border-top: 1px solid var(--color--electric);
    // }
  }

  // @media screen and (min-width: map-get($sections, 4)) {
  //   border-radius: var(--box-border-radius) 0 0 var(--box-border-radius);
  //   bottom: auto;
  //   left: auto;
  //   // right: 0;
  //   // top: 50%;
  //   // transform: translate3d(100%, -50%, 0);
  //   // width: 750px;

  //   &__toggle {
  //     border-radius: var(--box-border-radius) var(--box-border-radius) 0 0;
  //     left: auto;
  //     padding: .4em 2em !important;
  //     top: 50%;
  //     transform: translate3d(-50%, -50%, 0) rotate(-90deg) translateY(-50%);
  //   }

  //   &__content {
  //     justify-content: center;
  //   }

  //   .eswitch {
  //     border-radius: 0 0 var(--box-border-radius) var(--box-border-radius);
  //   }

  //   &.open {
  //     transform: translate3d(0, -50%, 0);

  //     &:before {
  //       content: initial;
  //     }
  //   }
  // }
}
