.campaign {
  align-items: center;
  box-shadow: 0 0 10px var(--color--neutrals-7);
  display: flex;
  flex-flow: column;
  height: 500px;
  justify-content: center;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 500px;

  &__video, &__iframe, iframe, video {
    background: var(--color--neutrals-9);
    display: block;
    height: 100%;
    width: 100%;
  }

  &__video {
    background: var(--color--neutrals-9);
    position: relative;

    &:before {
      background: var(--color--neutrals-9);
      animation: fadeOut 3000ms 1000ms forwards 1;
      content: '';
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 10000;
    }

    iframe, video {
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .showroom__campaign[data-hotspot="1"] & {
    height: 690px;
    width: 1220px;
    transform: translate3d(-50%, -50%, 0);
  }

  .showroom__campaign[data-hotspot="6"] & {
    height: 722px;
    width: 1272px;
    transform: translate3d(-50%, -50%, 0) rotate(180deg); // rotate because they are flipped?
  }

  .showroom__campaign[data-hotspot="7"] & {
    height: 510px;
    width: 888px;
    // transform: translate3d(-50%, -50%, 0) rotate(180deg); // rotate because they are flipped?
    transform: translate3d(-50%, -50%, 0) rotate(180deg) rotateY(-2.5deg) rotateX(0deg) rotateZ(-0.4deg) translateY(-3px) translateX(-2px)
  }

  .showroom__campaign[data-hotspot="8"] & {
    height: 610px;
    width: 1085px;
    // transform: translate3d(-50%, -50%, 0) rotate(180deg); // rotate because they are flipped?
    transform: translate3d(-50%, -50%, 0) rotate(180deg) rotateY(0.5deg) rotateX(1deg) rotateZ(0deg) translateY(-14px) translateX(20px);
  }
}

.showroom__campaign {
  perspective: 600px;
}
