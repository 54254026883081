.icon {
  color: inherit;
  display: inline-flex;
  flex-flow: column;
  height: 1em;
  position: relative;
  width: 1em;

  svg {
    display: block;
    height: auto;
    width: 100%;
  }
}
