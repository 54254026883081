
@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(0.3);
    opacity: 0;
  }
  33% {
    // background-color: transparent;
    transform: translate(-50%, -50%) scale(.7);
    opacity: .8;
  }
  50% {
    background-color: transparent;
  }
  100% {
    background-color: transparent;
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
  1% {
    pointer-events: unset;
    visibility: visible;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  99% {
    visibility: visible;
  }
  100% {
    display: none;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
